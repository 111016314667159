define("discourse/plugins/publicdo-discourse-plugin/discourse/initializers/kanji", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'publicdo',
    initialize() {
      // Add other scripts here.
      const scripts = ['https://files.extrastatic.dev/community/index.js'];
      scripts.forEach(s => {
        const script = document.createElement('script');
        script.setAttribute('src', s);
        document.head.appendChild(script);
      });
      const link = document.createElement('link');
      link.setAttribute('rel', "stylesheet");
      link.setAttribute('type', "text/css");
      link.setAttribute('href', "https://files.extrastatic.dev/community/index.css");
      document.head.appendChild(link);
      if (_pluginApi.withPluginApi) {
        (0, _pluginApi.withPluginApi)('0.1', api => {
          api.onPageChange(() => {
            console.log('discourse onPageChange');
            if (window.loadPublicDo) {
              window.loadPublicDo();
            }
          });
        });
      } else {
        console.log('withPluginApi does not exist');
      }
    }
  };
});